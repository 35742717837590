import React from 'react';
import { Link } from 'gatsby';

import { siteMetadata } from '../../gatsby-config';
import Card from 'components/Card';
import Meta from 'components/Meta';
import SplashPage from 'components/SplashPage';

const ButtonLink = ({ to, children }) => (
  <Link to={to} className="btn btn-primary btn-lg btn-block">
    {children}
  </Link>
);

const Index = ({ location }) => {
  return (
    <SplashPage location={location}>
      <Meta site={siteMetadata} />
      <h1 className="display-1">Ideal Abstraction</h1>
      <p>
        The software industry is filled with challenges involving both people
        and technology. In this complicated world, we’re here to help you look
        at things in new ways, that help you solve the problems you face.
      </p>
      <p>
        We’re a Seattle-based pair of developers with two decades of experience
        between us that includes time at Amazon and Microsoft - as well as
        smaller companies and independent projects.
      </p>
      <p>
        We know code, but we also know that’s only part of the equation. Working
        on software means working with other people, whether they’re members of
        your team or the people who use your product. We’ve got the theoretical
        grounding to understand what makes people tick in the form of cognitive
        science, and the real-world experience from time in developer management
        to know how it actually works in practice.
      </p>
      <p>
        We don't just know what we're talking about: we know how to explain it.
        Our experience in teaching ranges from curriculum development and
        presentation at Amazon through to classroom instruction through the
        University of Washington. Everyone learns differently, but everyone is
        capable of becoming better.
      </p>
      <p>
        We’re ready to come meet you, hear about your situation, and work with
        you to figure out how to improve you, your team, your company, and your
        world.
      </p>
      <div
        className="card-deck card-big-footers"
        style={{ marginBottom: '1rem' }}
      >
        <Card
          header={<h2 className="card-title">For Individuals</h2>}
          footer={<ButtonLink to="/individuals/">Learn more</ButtonLink>}
        >
          <p>
            <strong>We're personal trainers for your tech career.</strong>
          </p>
          <p>
            We provide mentoring and coaching for people in tech. We can help
            you get a job in the software industry, understand the dynamics of
            your team or company, or grow your skills - technical and social -
            to be better at what you do.
          </p>
        </Card>
        <Card
          header={<h2 className="card-title">For Organizations</h2>}
          footer={<ButtonLink to="/organizations/">Learn more</ButtonLink>}
        >
          <p>
            <strong>We bridge the technical and the human.</strong>
          </p>
          <p>
            We help your organization do greater things. Combining knowledge
            from software development, teaching, and management, we help you
            build stronger teams and tackle technical challenges.
          </p>
        </Card>
      </div>
      <p>
        There's a lot of ambiguity when it comes to working in software. What
        does the customer need? How does this function work? Why does the system
        keep crashing?
      </p>
      <p>
        We can't possibly list out every way we can help, but you're working in
        software, and that means you're someone who looks for solutions to
        problems.
      </p>
      <p>
        If there's something you think we can help with, get in touch! We'd love
        to hear from you, find out more about your situation, and discuss
        whether there's something we can do to help.
      </p>
      <ButtonLink to={'/contact/'}>Contact us!</ButtonLink>
    </SplashPage>
  );
};

export default Index;
