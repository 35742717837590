import React from 'react';

import './style.scss';

const Card = ({ header, footer, children }) => (
  <div className="card">
    <div className="card-header">{header}</div>
    <div className="card-body">{children}</div>
    <div className="card-footer">{footer}</div>
  </div>
);

export default Card;
