import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import get from 'lodash/get';
import BackgroundImage from 'gatsby-background-image';

import './style.scss';

const SplashPage = ({ children, data }) => {
  const bg = get(data, 'bg.childImageSharp.fluid');

  return (
    <BackgroundImage fluid={bg} className="splash">
      <div className="container">{children}</div>
    </BackgroundImage>
  );
}

const query = graphql`
  query SplashPageQuery {
    bg: file(name: { eq: "ashkan-forouzani-1273288-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 6000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default (props) => (<StaticQuery
  query={query}
  render={(data) => <SplashPage data={data} {...props} />}
/>);
